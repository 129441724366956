import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Strong, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Menu | DEUTSCHE SPORTWELT PUB
			</title>
			<meta name={"description"} content={"Savor the fusion of athletic passion and German culinary brilliance at Deutsche Sportwelt Pub."} />
			<meta property={"og:title"} content={"Menu | DEUTSCHE SPORTWELT PUB"} />
			<meta property={"og:description"} content={"Savor the fusion of athletic passion and German culinary brilliance at Deutsche Sportwelt Pub."} />
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/pub-location-icon-free-vector.jpg?v=2023-10-17T16:23:32.147Z"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/menu_9.jpg?v=2023-10-18T07:11:07.802Z) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
				width="80%"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline1">
					MENU{"\n\n"}
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					Embark on a culinary journey through the Deutsche Sportwelt Pub's menu, where each dish and drink is inspired by the exhilarating world of sports and the rich flavours of German tradition. From our signature brews that capture the essence of legendary moments to dishes that reflect the stamina and spirit of athletes, our menu promises a gastronomic experience that transcends the ordinary.{"\n\n"}
					<br />
					<br />
					<br />
					<Strong>
						{"\n"}Signature Brews{"\n"}
					</Strong>
					<br />
					<Strong>
						Gymnasium Gold
					</Strong>
					{" "}- A lager with a crisp finish, reminiscent of golden victories.{"\n"}
					<br />
					<Strong>
						Legend's Dunkel
					</Strong>
					{" "}- A dark, malty beer celebrating deep-rooted stories.{"\n"}
					<br />
					<Strong>
						Athlete's Ale
					</Strong>
					{" "}- A refreshing ale, brewed to quench the thirst after a hearty game.
					<br />
					<br />
					<br />
					<Strong>
						{"\n"}Bites{"\n"}
					</Strong>
					<br />
					<Strong>
						Sprinter's Pretzels
					</Strong>
					{" "}- Classic German pretzels with a twist of rye and a dash of salt.{"\n"}
					<br />
					<Strong>
						Goalkeeper's Goulash
					</Strong>
					{" "}- A hearty beef stew, inspired by the robust spirit of the game.{"\n"}
					<br />
					<Strong>
						Javelin Jerky
					</Strong>
					{" "}- Tender meat strips marinated in our signature sauce, dried to perfection.
					<br />
					<br />
					<br />
					<Strong>
						{"\n"}Main Course{"\n"}
					</Strong>
					<br />
					<Strong>
						Marathoner's Schnitzel{" "}
					</Strong>
					- A juicy schnitzel paired with herb-infused potatoes and tangy kraut.{"\n"}
					<br />
					<Strong>
						High Jumper's Haxe
					</Strong>
					{" "}- Roasted pork knuckle served with a side of mashed peas and a dribble of apple mustard sauce.{"\n"}
					<br />
					<Strong>
						Fencer's Flammkuchen{" "}
					</Strong>
					- A thin-crust pizza topped with creamy white sauce, onions, and smoky bacon.
					<br />
					<br />
					<Strong>
						{"\n\n"}Desserts{"\n"}
						<br />
						Victory Strudel -{" "}
					</Strong>
					Layers of flaky pastry filled with spiced apples and crowned with vanilla custard.
					<Strong>
						{"\n"}
						<br />
						Bobsleigh Black Forest Cake -{" "}
					</Strong>
					A twist on the classic, with cherries soaked in our signature brew.{"\n\n\n\n\n\n\n\n\n\n\n\n"}
				</Text>
			</Box>
		</Section>
		<Components.FoodImages />
		<Components.Footer />
	</Theme>;
});